import React from "react"
import Picture from "./../images/svg/in-sync.svg"

const MainHeader = () => (
  <section
    className="header-1 section-rotate fixed-height"
    data-offset-top="#header-main"
  >
    <div className="section-inner bg-gradient-primary" />
    <div className="pt-7 position-absolute middle right-0 col-lg-7 col-xl-6 d-none d-lg-block">
      <figure className="w-100" style={{ maxWidth: "1000px" }}>
        <img src={Picture} style={{ width: "90%" }} alt={"Etusivu"} />
      </figure>
    </div>
    <div className="bg-absolute-cover bg-size--contain d-flex align-items-center">
      <figure className="w-100 d-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1500 820"
          className="injected-svg svg-inject"
          style={{ height: "1000px" }}
        >
          <path
            className="fill-cyan"
            d="M 153.37 455.293 L 143.264 444.989 C 142.269 443.976 142.269 442.371 143.264 441.358 L 153.37 431.055 C 154.364 430.042 155.938 430.042 156.932 431.055 L 167.038 441.358 C 168.032 442.371 168.032 443.976 167.038 444.989 L 156.932 455.293 C 156.02 456.306 154.364 456.306 153.37 455.293 Z"
          />
          <circle className="fill-pink" cx="1184.025" cy="85.335" r="9.723" />
          <circle
            className="fill-yellow"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="320.504"
            cy="209.51"
            r="12.587"
          />
          <path
            className="fill-blue"
            d="M 42.564 -16.417 C 43.206 -18.074 44.682 -19.262 46.438 -19.535 L 62.879 -22.089 C 64.634 -22.362 66.401 -21.677 67.515 -20.294 L 77.948 -7.333 C 79.062 -5.949 79.353 -4.076 78.712 -2.419 L 72.703 13.096 C 72.061 14.753 70.585 15.941 68.829 16.215 L 52.388 18.769 C 50.633 19.042 48.865 18.358 47.752 16.973 L 37.319 4.012 C 36.205 2.628 35.914 0.755 36.555 -0.902 L 42.564 -16.417 Z"
          />
          <path
            className="fill-pink"
            d="M 1128.161 586.512 C 1128.462 585.735 1129.154 585.178 1129.977 585.05 L 1137.684 583.853 C 1138.506 583.725 1139.335 584.046 1139.857 584.695 L 1144.747 590.77 C 1145.269 591.419 1145.406 592.297 1145.105 593.073 L 1142.289 600.346 C 1141.988 601.123 1141.296 601.68 1140.473 601.808 L 1132.767 603.005 C 1131.944 603.133 1131.115 602.812 1130.593 602.163 L 1125.703 596.088 C 1125.181 595.439 1125.044 594.561 1125.345 593.785 L 1128.161 586.512 Z"
          />
          <path
            className="fill-purple"
            d="M 109.272 171.631 C 109.643 170.672 110.497 169.986 111.512 169.828 L 121.017 168.352 C 122.032 168.193 123.054 168.59 123.698 169.389 L 129.729 176.883 C 130.373 177.683 130.542 178.766 130.171 179.723 L 126.697 188.693 C 126.326 189.651 125.473 190.339 124.458 190.497 L 114.952 191.973 C 113.937 192.131 112.916 191.736 112.272 190.935 L 106.24 183.442 C 105.596 182.641 105.428 181.559 105.798 180.601 L 109.272 171.631 Z"
          />
          <circle
            className="fill-cyan"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="720.584"
            cy="740.881"
            r="9.997"
          />
          276.134 1400.729 276.244 L 1394.16 277.265 C 1393.459 277.373 1392.752
          277.1 1392.307 276.547 L 1388.138 271.368 C 1387.693 270.815 1387.577
          270.066 1387.833 269.404 L 1390.234 263.204 Z"/>
        </svg>
      </figure>
    </div>
    <div className="container py-5 pt-lg-10 d-flex align-items-center position-relative zindex-100 fixed-height">
      <div className="col">
        <div className="row d-flex">
          <div className="col-lg-5 col-xl-6 text-center text-lg-left">
            <div>
              <h2 className="text-white display-4 font-weight-light mb-4">
                Web-sovellukset yhdestä osoitteesta
              </h2>
              <p className="lead text-white d-none d-sm-block">
                Toteutamme alusta loppuun mm. verkkopohjaiset
                toiminnanohjausjärjestelmät, extranetit, intranetit, verkkosivut
                sekä erilaiset sähköiset kartoitustyökalut myyjien tai
                tarkastajien työtä tukemaan. Hoidamme myös rakentamiemme
                web-sovellusten ylläpidon.
              </p>
              <figure
                className="w-100 mb-n5 d-block d-sm-none"
                style={{ maxWidth: "1000px" }}
              >
                <img src={Picture} style={{ width: "85%" }} alt={"Etusivu"} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default MainHeader
