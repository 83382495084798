import React from "react"
import PropTypes from "prop-types"

const Testimonial = ({ name, company, text }) => (
  <div className="card bg-section-secondary rounded border-0">
    <div className="card-body pb-2">
      <h5 className="h6 mb-0">{company}</h5>
      <small className="d-block text-muted">{name}</small>
      <p className="mt-4 lh-180">{text}</p>
    </div>
  </div>
)

Testimonial.propTypes = {
  name: PropTypes.node.isRequired,
  company: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
}

Testimonial.defaultProps = {
  name: "",
  company: "",
  text: "",
}

export default Testimonial
