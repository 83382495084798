import React from "react"
import { Link } from "gatsby"
import ScrollAnim from "react-animate-on-scroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainHeader from "../components/main-header"
import ContactSlice from "../components/contact-slice"
import Testimonial from "../components/testimonial"

import OPLogo from "../images/logos/op-pohjola.png"
import OuluLogo from "../images/logos/oulu-sivistys.png"
import AbloyLogo from "../images/logos/abloy.png"
import KauppuriLogo from "../images/logos/kauppuri.png"
import KrautaLogo from "../images/logos/k-rauta.png"
import IntersportLogo from "../images/logos/intersport.png"
import BudgetsportsLogo from "../images/logos/budgetsport.png"
import KemiLogo from "../images/logos/kemi-shipping.png"
import SafetumLogo from "../images/logos/Safetum_Logo_Midnight_RGB.png"
import SmartiaLogo from "../images/logos/smartia.png"
import TopiLogo from "../images/logos/topi-keittiot.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Picture from "../images/blog-post.jpg"

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Etusivu"
          description={
            "Uudistamme liiketoimintasi digitaalisin keinoin. Toteutamme huolella suunnitellut sähköiset työkalut kaikenkokoisille yrityksille."
          }
          image={Picture}
        />
        <MainHeader />
        <div className="container slice slice-lg">
          <div className="row mb-6">
            <div className="col-lg-7">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <div
                  className={
                    "card bg-primary shadow-lg rounded-lg p-5 pr-6 border-0"
                  }
                >
                  <h2 className="text-white mb-3">
                    Sähköistämme liiketoimintasi
                  </h2>
                  <p className="text-white">
                    Uudistamme liiketoimintasi digitaalisin keinoin. Toteutamme
                    huolella suunnitellut sähköiset työkalut kaikenkokoisille
                    yrityksille. Ohjelmistokehitys toteutetaan yhteistyössä
                    asiakkaan kanssa, asiakkaan toiveet ja liiketoiminnan
                    erityistarpeet huomioiden. Palvelualueenamme on koko suomi.
                  </p>
                </div>
              </ScrollAnim>
            </div>
            <div className="col-lg-5">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <div
                  className={
                    "card bg-white rounded-lg mt-6 mb-n6 ml-lg-n6 p-5 shadow-lg border-0"
                  }
                >
                  <h2 className="mb-3">Vankalla ammattitaidolla</h2>
                  <p>
                    Kokenut tiimimme toteuttaa vaativimmatkin
                    ohjelmistokehitykset sujuvasti alkukartoituksesta
                    käyttöönottoon. Tarjoamme kehittämiimme verkkopalveluihin
                    myös kustannustehokkaan ylläpidon. Kerro meille tarpeesi, me
                    kerromme miten se ratkaistaan. Ota siis yhteyttä jo tänään,
                    autamme mielellämme!
                  </p>
                </div>
              </ScrollAnim>
            </div>
          </div>
        </div>
        <div className="bg-section-secondary slice slice-lg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ScrollAnim
                  animateIn="fadeIn"
                  duration="0.5"
                  animateOnce="true"
                  offset="120"
                >
                  <a
                    href={"https://tuntikirja.fi"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <div className={"card p-2"}>
                      <div className="px-4 py-5">
                        <FontAwesomeIcon
                          icon="hourglass-start"
                          className={"fa-3x mb-4 text-primary"}
                        />
                        <h5>Tuntikirja.fi</h5>
                        <p className="text-muted">
                          Tuntikirja.fi on ratkaisu työajan seurantaan. Se on
                          helppokäyttöinen ja järkihintaan aina saatavilla oleva
                          verkkopalvelu.
                        </p>
                        <div
                          style={{ width: "fit-content" }}
                          className="link link-underline-primary"
                        >
                          Kokeile nyt
                        </div>
                      </div>
                    </div>
                  </a>
                </ScrollAnim>
              </div>
              <div className="col-lg-6">
                <ScrollAnim
                  animateIn="fadeIn"
                  duration="0.5"
                  animateOnce="true"
                  offset="120"
                >
                  <Link to={"/some-ja-nuoret"}>
                    <div className={"card p-2"}>
                      <div className="px-4 py-5">
                        <FontAwesomeIcon
                          icon="hashtag"
                          className="fa-3x mb-4 text-primary"
                        />
                        <h5>Some ja nuoret -tutkimus</h5>
                        <p className="text-muted">
                          Some ja nuoret -tutkimus on koko Suomen kattava
                          verkkokysely suomalaisten nuorten ja nuorten aikuisten
                          sosiaalisen median käytöstä.
                        </p>
                        <div
                          style={{ width: "fit-content" }}
                          className="link link-underline-primary"
                        >
                          2022 tulokset julkaistu!
                        </div>
                      </div>
                    </div>
                  </Link>
                </ScrollAnim>
              </div>
            </div>
          </div>
        </div>
        <div className="slice slice-lg bg-white">
          <div className={"particles"} />
          <div className="container text-center">
            <h2>Asiakkaitamme matkan varrelta</h2>
            <div className="fluid-paragraph mt-3">
              <p className="lead lh-180">
                Asiakkaamme, tai ennemmin kai pitäisi kutsua heitä kumppaneiksi,
                ovat usein toimineet kanssamme jo vuosien ajan.
              </p>
            </div>
            <div className="row justify-content-center align-items-center mt-5 pb-4">
              <div className="client col-4 col-md-2 py-3">
                <img alt="Safetum logo" src={SafetumLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Kemishipping logo" src={KemiLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Kauppuri logo" src={KauppuriLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="OP Pohjola logo" src={OPLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Intersport logo" src={IntersportLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img
                  alt="Oulu sivistys ja kulttuuripalvelut logo"
                  src={OuluLogo}
                />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="K-Rauta logo" src={KrautaLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Abloy logo" src={AbloyLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Topikeittiöt logo" src={TopiLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Smartia logo" src={SmartiaLogo} />
              </div>
              <div className="client col-4 col-md-2 py-3">
                <img alt="Budget sports logo" src={BudgetsportsLogo} />
              </div>
            </div>
            <div
              id="indicators"
              className="col-md-7 carousel slide mx-auto mt-5"
              data-ride="carousel"
              data-interval="10000"
            >
              <ol className="carousel-indicators">
                <li
                  data-target={"#indicators"}
                  data-slide-to="0"
                  className="active"
                />
                <li data-target={"#indicators"} data-slide-to="1" />
                <li data-target={"#indicators"} data-slide-to="2" />
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item">
                  <Testimonial
                    name="Teemu Kajava"
                    company="Safetum Oy"
                    text="Olemme tehneet vuosien ajan yhteistyötä Ebrandin kanssa erikokoisten ohjelmistoprojektien parissa. Yhteistyö on ollut aina mutkatonta. Projektit on hoidettu ajallaan, laadukkaasti ja sovitulla tavalla."
                  />
                </div>
                <div className="carousel-item active">
                  <Testimonial
                    name="Ulla Aho"
                    company="Kemi Shipping Oy"
                    text="Meillä on erittäin hyvät kokemukset yhteistyöstä Ebrandin kanssa. He ovat suunnitelleet meille yrityksen julkiset kotisivut ja intranetin henkilökunnan käyttöön. Olemme tyytyväisiä kokonaisvaltaiseen palveluun, ratkaisut löytyvät nopeasti kaikkiin ongelmatilanteisiin ja lähes kaikki ideat ja toiveet on pystytty toteuttamaan."
                  />
                </div>
                <div className="carousel-item">
                  <Testimonial
                    name='Marko "Masco" Seppälä'
                    company="Oulun kaupunki, Sivistys- ja kulttuuripalvelut"
                    text="Oulun kaupunki on tilannut vuodesta 2013 lähtien Some ja nuoret-kyselykartoituksen Ebrandilta. Yhteistyö on sujunut hyvin ja olemme aina tienneet tilatessamme, että mitä saamme: taattua laatua."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactSlice />
      </Layout>
    )
  }
}
